import { useEffect } from "react";

const ClassTimes = () => {
    useEffect(() => {
        
    }, []);

    return (
        <div>

        </div>
    );
}

export default ClassTimes;